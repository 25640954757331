<template>
  <div id="discussionBoard" ref="discussionBoard">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ $t(item.text) }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="12" sm="8">
        <v-text-field
          :label="$i18n.t('Search by Topic or Tag')"
          prepend-inner-icon="search"
          v-model="search"
          @keyup="getPosts"
        ></v-text-field>
        <div class="addTopic" v-if="user_access || groupData.valid_unitll">
          <v-form ref="form" v-model="valid">
            <v-text-field
              solo
              v-model="post.title"
              :placeholder="$i18n.t('New Topic')"
              @focus="focus"
              :rules="[validationRules.required, validationRules.maxLength225]"
            ></v-text-field>
            <!-- <p v-if="postTitleError != ''" class="post-error">
              {{ postTitleError }}
            </p>-->
            <v-textarea
              v-if="post.title != ''"
              solo
              v-model="post.body"
              :placeholder="$i18n.t('Details')"
              @focus="focus"
              :rules="[validationRules.required]"
              style="margin-top: 3px"
            ></v-textarea>
            <!-- <p v-if="postBodyError != ''" class="post-error">
              {{ postBodyError }}
            </p>-->
            <vue-tags-input
              v-if="post.title != ''"
              v-model="tag"
              :placeholder="$i18n.t('Add Tag')"
              :autocomplete-items="filteredItems"
              style="
                width: 100%;
                max-width: unset;
                margin-bottom: 20px;
                margin-top: 3px;
              "
              :tags="post.tags"
              @tags-changed="(newTags) => (post.tags = newTags)"
            />
            <v-row>
              <v-col>
                <p v-if="fileNames != ''">{{ fileNames }}</p>
                <input
                  type="file"
                  class="myFileBrowser"
                  multiple
                  ref="attachment"
                  @change="readFiles"
                />
                <v-icon class="main-color" style="cursor: pointer"
                  >attach_file</v-icon
                >
              </v-col>
              <v-col
                :class="
                  currentAppLocale == 'undefined' || currentAppLocale == 'en'
                    ? 'text-right'
                    : 'text-left'
                "
              >
                <v-btn
                  @click.prevent="savePost"
                  :loading="loading"
                  :disabled="loading"
                  class="text-right"
                  icon
                >
                  <v-icon class="main-color transform-rotate-180">send</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div class="discussion" style="padding: 7px">
          <v-row>
            <p class="title">{{ $t("Recent Activity") }}</p>
          </v-row>
          <div
            class="preloader"
            style="text-align: center"
            v-if="loadingNewPost"
          >
            <img
              src="../../assets/img/preloader.gif"
              id="preloader-custom"
              alt="preloader"
              class
            />
          </div>
          <v-row
            class="item"
            v-for="(post, postIndex) in posts"
            :key="postIndex"
            style="margin-top: -10px"
          >
            <!-- <v-col cols="8" sm="8" style="margin-top:-10px;"> -->
            <v-row>
              <v-col sm="2" md="2">
                <v-responsive
                  v-if="post.post_user_logo"
                  style="display: inline-block"
                  class="rounded-circle"
                >
                  <v-img
                    width="50"
                    height="50"
                    class="member-img rounded-circle"
                    style="display: inline-block"
                    :src="post.post_user_logo"
                  />
                </v-responsive>

                <v-responsive
                  v-else
                  class="rounded-circle"
                  style="display: inline-block"
                >
                  <v-img
                    width="50"
                    height="50"
                    class="member-img rounded-circle"
                    style="display: inline-block"
                    src="../../assets/student.png"
                  />
                </v-responsive>
              </v-col>
              <v-col sm="8" md="8" style="margin-left: -2rem">
                <div class="person-info" style="margin-left: -30px">
                  <p class="main-color name">
                    <strong>{{ post.post_user }}</strong>
                  </p>
                  <p class="time">{{ post.time }}</p>
                </div>
              </v-col>
              <v-col sm="2" md="2" class="text-right">
                <v-icon
                  class="main-color editAction"
                  style="cursor: pointer"
                  @click="editPost(postIndex)"
                  v-if="post.can_edit"
                  >edit</v-icon
                >
                <v-icon
                  class="main-color deleteAction"
                  style="cursor: pointer"
                  @click="deletePostDialogOpen(postIndex)"
                  v-if="post.can_delete"
                  >delete</v-icon
                >
              </v-col>
            </v-row>
            <!-- </v-col> -->

            <v-col cols="12" style="padding: 20px 0">
              <div class="topicContent">
                <p class="type">
                  <strong>{{ post.title }}</strong>
                </p>
                <!-- <p class="topic">
                  {{ post.body }}
                </p>-->
                <v-col cols="12">
                  <div style="word-break: break-all !important">
                    <chat-line :line="post.body" class="topic"></chat-line>
                  </div>
                </v-col>
                <div class="attachments">
                  <v-row class="images" v-if="post.attachments.image">
                    <v-col
                      cols="6"
                      sm="3"
                      v-for="(image, index) in post.attachments.image"
                      :key="index"
                    >
                      <a :href="image.url" target="_blank" class="imgContainer">
                        <img :src="image.url" class="postImg" />
                      </a>
                    </v-col>
                  </v-row>
                  <v-row class="other">
                    <p style="width: 100%">
                      <!-- <strong>Other attachments:</strong> -->
                    </p>
                    <div
                      class="video"
                      v-if="
                        post.attachments.video &&
                        post.attachments.video.length > 0
                      "
                    >
                      <v-icon class="main-color">movie</v-icon>
                      <a
                        :href="file.url"
                        target="_blank"
                        v-for="(file, index) in post.attachments.video"
                        :key="index"
                        >{{ file.name }}</a
                      >
                    </div>
                    <div
                      class="document"
                      v-if="
                        post.attachments.document &&
                        post.attachments.document.length > 0
                      "
                    >
                      <v-icon class="main-color">description</v-icon>
                      <a
                        :href="file.url"
                        target="_blank"
                        v-for="(file, index) in post.attachments.document"
                        :key="index"
                        >{{ file.name }}</a
                      >
                    </div>
                    <div
                      class="audio"
                      v-if="
                        post.attachments.audio &&
                        post.attachments.audio.length > 0
                      "
                    >
                      <v-icon class="main-color">audiotrack</v-icon>
                      <a
                        :href="file.url"
                        target="_blank"
                        v-for="(file, index) in post.attachments.audio"
                        :key="index"
                        >{{ file.name }}</a
                      >
                    </div>
                  </v-row>
                  <div class="comment-actions" v-if="post.can_edit">
                    <!-- <span
                      class="main-color editAction"
                      style="cursor: pointer;"
                      @click="EditComment(postIndex, index)"
                      >Edit</span
                    >
                    <span
                      class="main-color deleteAction"
                      style="cursor: pointer;"
                      @click="deleteComment(postIndex, index)"
                      >Delete</span
                    >-->
                  </div>
                </div>
              </div>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  class="text-right main-color topicInfo"
                  style="padding: 5px"
                >
                  <v-icon
                    class="main-color text-left reaction"
                    @click="showReplyArea(postIndex)"
                    >message</v-icon
                  >
                  <span @click="showComment(postIndex)" style="cursor: pointer"
                    >{{ $t("Reply") }}({{ post.comments_count }})</span
                  >
                </v-col>
              </v-row>
              <!-- <hr /> -->
              <div
                class="comments"
                v-if="(post.show_reply_area && roomIsValid) || user_access"
                style="margin-top: 20px"
              >
                <v-row>
                  <v-col cols="1">
                    <v-responsive
                      class="rounded-circle"
                      style="display: inline-block"
                    >
                      <img
                        width="50"
                        height="50"
                        style="display: inline-block"
                        src="../../assets/avatar.png"
                        class="member-img rounded-circle"
                      />
                    </v-responsive>
                  </v-col>
                  <v-col cols="11">
                    <v-textarea
                      solo
                      v-model="comment[postIndex].body"
                      :placeholder="$i18n.t('Reply') + '...'"
                      @focus="focus"
                      rows="1"
                      style="border-radius: 20px; width: 93%"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p v-if="commentFileNames != ''">{{ commentFileNames }}</p>
                    <!-- class="myFileBrowser" -->
                    <input
                      type="file"
                      class="myFileBrowser"
                      multiple
                      ref="commentattach"
                      @change="readFilesComment(postIndex)"
                    />
                    <v-icon class="main-color" style="cursor: pointer"
                      >attach_file</v-icon
                    >
                  </v-col>
                  <v-col
                    :class="
                      currentAppLocale == 'undefined' || currentAppLocale == 'en'
                        ? 'text-right'
                        : 'text-left'
                    "
                  >
                    <v-btn
                      @click.prevent="
                        saveComment(post.id, postIndex, comment[postIndex].body)
                      "
                      :loading="Replyloading"
                      :disabled="Replyloading"
                      icon
                    >
                      <v-icon class="main-color transform-rotate-180"
                        >send</v-icon
                      >
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
              <div class="list-comments" v-if="post.show_comments">
                <div
                  class="comment-item"
                  v-for="(comment, index) in post.comments"
                  :key="index"
                >
                  <v-row style="padding: 0">
                    <v-col cols="2" sm="2">
                      <v-responsive
                        v-if="comment.logo"
                        class="rounded-circle"
                        style="display: inline-block"
                      >
                        <v-img
                          :src="comment.logo"
                          width="50"
                          height="50"
                          class="member-img rounded-circle"
                          style="display: inline-block"
                        />
                      </v-responsive>
                      <v-responsive
                        v-else
                        class="rounded-circle"
                        style="display: inline-block"
                      >
                        <v-img
                          width="50"
                          height="50"
                          class="member-img rounded-circle"
                          style="display: inline-block"
                          src="../../assets/avatar.png"
                        />
                      </v-responsive>
                    </v-col>
                    <v-col cols="8" style="margin-left: -5rem">
                      <div class="person-info">
                        <p class="main-color name">
                          <strong>{{ comment.user }}</strong>
                        </p>
                        <p class="time">{{ comment.time }}</p>
                      </div>
                    </v-col>

                    <v-col class="text-right" cols="2" sm="2">
                      <v-icon
                        v-if="comment.can_edit"
                        class="main-color editAction"
                        style="cursor: pointer"
                        @click="editComment(postIndex, index)"
                        >edit</v-icon
                      >
                      <v-icon
                        v-if="comment.can_delete"
                        class="main-color deleteAction"
                        style="cursor: pointer"
                        @click="deleteCommentDialogOpen(postIndex, index)"
                        >delete</v-icon
                      >
                    </v-col>
                  </v-row>
                  <div class="comment-body" style="margin-left: 40px">
                    <!-- <chat-line :line="comment.body"></chat-line> -->
                    <div v-if="comment.showMore">
                      <!-- {{ comment.body.substring(0, 130) }} -->
                      <chat-line
                        :line="comment.body.substring(0, 130)"
                      ></chat-line>
                      <div style="margin-top: -10px">
                        <a
                          v-if="comment.showMore && comment.body.length > 130"
                          style="color: #7297ff; margin-top: -40px"
                          @click="comment.showMore = false"
                          >{{ $t("show more") }}</a
                        >
                      </div>
                    </div>
                    <div v-if="!comment.showMore">
                      <chat-line :line="comment.body"></chat-line>
                      <div style="margin-top: -10px">
                        <a
                          v-if="!comment.showMore"
                          style="color: #7297ff"
                          @click="comment.showMore = true"
                          >{{ $t("show less") }}</a
                        >
                      </div>
                    </div>

                    <div class="attachments">
                      <v-row class="images" v-if="comment.attachments.image">
                        <v-col
                          cols="6"
                          sm="3"
                          v-for="(image, index) in comment.attachments.image"
                          :key="index"
                        >
                          <a
                            :href="image.url"
                            target="_blank"
                            class="imgContainer"
                          >
                            <img :src="image.url" class="postImg" />
                          </a>
                        </v-col>
                      </v-row>
                      <v-row class="other">
                        <p style="width: 100%">
                          <!-- <strong>Other attachments:</strong> -->
                        </p>
                        <div
                          class="video"
                          v-if="
                            comment.attachments.video &&
                            comment.attachments.video.length > 0
                          "
                        >
                          <v-icon class="main-color">movie</v-icon>
                          <a
                            :href="file.url"
                            target="_blank"
                            v-for="(file, index) in comment.attachments.video"
                            :key="index"
                            >{{ file.name }}</a
                          >
                        </div>
                        <div
                          class="document"
                          v-if="
                            comment.attachments.document &&
                            comment.attachments.document.length > 0
                          "
                        >
                          <v-icon class="main-color">description</v-icon>
                          <a
                            :href="file.url"
                            target="_blank"
                            v-for="(file, index) in comment.attachments
                              .document"
                            :key="index"
                            >{{ file.name }}</a
                          >
                        </div>
                        <div
                          class="audio"
                          v-if="
                            comment.attachments.audio &&
                            comment.attachments.audio.length > 0
                          "
                        >
                          <v-icon class="main-color">audiotrack</v-icon>
                          <a
                            :href="file.url"
                            target="_blank"
                            v-for="(file, index) in comment.attachments.audio"
                            :key="index"
                            >{{ file.name }}</a
                          >
                        </div>
                      </v-row>
                      <!-- <div class="comment-actions" v-if="comment.can_edit">
                        <v-icon
                          class="main-color editAction"
                          style="cursor: pointer;"
                          @click="editComment(postIndex, index)"
                        >edit</v-icon>
                        <v-icon
                          class="main-color deleteAction"
                          style="cursor: pointer;"
                          @click="deleteCommentDialogOpen(postIndex, index)"
                        >delete</v-icon>
                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div
                class="preloader"
                style="text-align: center"
                v-if="loadingMorePosts"
              >
                <img
                  src="../../assets/img/preloader.gif"
                  id="preloader-custom"
                  alt="preloader"
                  class
                />
              </div>
            </v-col>
            <v-col cols="12">
              <center>
                <a
                  @click="showMorePosts"
                  v-if="page < length"
                  style="color: #7297ff; text-align: center"
                  >{{ $t("show more") }}</a
                >
              </center>
            </v-col>
          </v-row>
        </div>
        <!-- <div class="text-center pt-2" v-can="'list-admission-exam'">
          <v-pagination
            v-model="page"
            :length="length"
            :page="page"
            :total-visible="15"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>-->
      </v-col>
      <v-col cols="12" sm="4">
        <div class="tags">
          <v-row>
            <v-col cols="8" sm="8">
              <h3 class="main-color">
                <strong>{{ $t("Recent tags") }}</strong>
              </h3>
            </v-col>
            <v-col cols="4" sm="4">
              <span class="text-right cat-num main-color"
                >{{ groupData.tags_count }} {{ $t("Tags") }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-chip-group column active-class="blue--text">
              <div
                v-for="(tag, index) in groupData.tags"
                :key="index"
                @click="filterByTag(tag.name)"
              >
                <v-chip
                  class="ma-2"
                  color="#EEF3FF"
                  style="cursor: pointer; color: #7297ff"
                  >{{ tag.name }}</v-chip
                >
              </div>
            </v-chip-group>
          </v-row>
        </div>
        <div class="about-group">
          <v-row>
            <v-col cols="8" sm="8">
              <h3 class="main-color">
                <strong>{{ $t("About Group") }}</strong>
              </h3>
            </v-col>
            <v-col cols="12" sm="12">
              <p class="desc">
                {{ groupData.about_group }}
              </p>
              <p class="desc">
                <b>{{ $t("Creation Data") }} : </b>
                <span>{{ groupData.created_at }}</span>
              </p>

              <p class="desc" v-if="groupData.valid_unitll_data">
                <b>{{ $t("Valid Until") }} : </b>
                <span>{{ groupData.valid_unitll_data }}</span>
              </p>

              <p class="desc">
                <span class="square">
                  <v-icon
                    v-if="groupData.need_approve"
                    class="pt-2 text-center center"
                    color="green"
                    style="margin-top: -10px"
                    dark
                    >check</v-icon
                  >

                  <v-icon
                    v-else
                    color="red"
                    style="margin-top: -9px"
                    dark
                    class="pt-2 text-center center"
                    >close</v-icon
                  >
                </span>
                <b>{{ $t("Post Need Approve") }}</b>
              </p>

              <p class="desc">
                <span class="square">
                  <v-icon
                    v-if="groupData.replay_need_approve"
                    class="pt-2 text-center center"
                    color="green"
                    style="margin-top: -10px"
                    dark
                    >check</v-icon
                  >

                  <v-icon
                    v-else
                    color="red"
                    style="margin-top: -9px"
                    dark
                    class="pt-2 text-center center"
                    >close</v-icon
                  >
                </span>
                <b>{{ $t("Replay Need Approve") }}</b>
              </p>
            </v-col>
          </v-row>
        </div>

        <div class="members">
          <v-row>
            <v-col cols="8" sm="8">
              <h3 class="main-color">
                <strong>{{ $t("Members") }}</strong>
              </h3>
            </v-col>
            <v-col cols="4" sm="4">
              <span class="text-right cat-num main-color"
                >{{ groupData.members_count }} {{ $t("Members") }}</span
              >
            </v-col>

            <v-col
              cols="6"
              sm="4"
              md="3"
              v-for="(member, index) in groupData.members"
              :key="index"
              style="text-align: center"
            >
              <!-- <v-responsive
                v-if="member.logo"
                class="rounded-circle"
                height="50"
                width="50"
              > -->
              <v-img
                v-if="member.logo"
                width="50"
                height="50"
                class="member-img rounded-circle"
                style="display: inline-block"
                :src="member.logo"
              />
              <!-- </v-responsive> -->

              <!-- <v-responsive
                v-else
                class="rounded-circle"
                height="50"
                width="50"
                style="text-align:center"
              > -->
              <v-img
                width="50"
                height="50"
                class="member-img rounded-circle"
                style="display: inline-block"
                src="../../assets/student.png"
              />
              <!-- </v-responsive> -->

              <p style="font-size: 8px; text-align: center">
                {{ member.name }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div
          class="about-group"
          style="margin-top: 20px"
          v-if="groupData.can_approve && groupData.pending_posts.length > 0"
        >
          <v-row>
            <v-col cols="12" sm="12">
              <h3 class="main-color">
                <strong
                  @click="OpenDiscussionRequests"
                  style="cursor: pointer"
                  >{{ $t("Discussion Requests") }}</strong
                >
              </h3>
            </v-col>
            <v-col cols="12" sm="12">
              <table class="table table-hover">
                <tr>
                  <th style="text-align: center">{{ $t("Topic") }}</th>
                  <th style="text-align: center">{{ $t("By") }}</th>
                  <th style="text-align: center">{{ $t("Actions") }}</th>
                </tr>
                <tr
                  v-for="(pendingPost, index) in groupData.pending_posts"
                  :key="index"
                >
                  <td class="main-color" style="text-align: center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <p dark v-on="on" v-if="pendingPost.title">
                          <router-link
                            target="_blank"
                            :to="
                              '/discussionrequests/postApproval/' +
                              roomId +
                              '/' +
                              pendingPost.id
                            "
                            >{{ pendingPost.title.slice(0, 10) }}</router-link
                          >
                        </p>
                      </template>
                      <span>
                        <router-link
                          target="_blank"
                          :to="
                            '/discussionrequests/postApproval/' +
                            roomId +
                            '/' +
                            pendingPost.id
                          "
                          >{{ pendingPost.title }}</router-link
                        >
                      </span>
                    </v-tooltip>
                    <!-- {{ pendingPost.title }} -->
                  </td>
                  <td class="main-color" style="text-align: center">
                    {{ pendingPost.by }}
                  </td>
                  <td class="main-color" style="text-align: center">
                    <v-btn
                      @click="approve(pendingPost.id)"
                      :disabled="actionLoading"
                      :loading="actionLoading"
                      icon
                    >
                      <v-icon color="#5fb870">check</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </div>
        <div
          class="about-group"
          style="margin-top: 20px"
          v-if="groupData.can_approve && commentsNeedApprove.length > 0"
        >
          <v-row>
            <v-col cols="12" sm="12">
              <h3 class="main-color">
                <strong
                  @click="OpenDiscussionCommentsRequests"
                  style="cursor: pointer"
                  >{{ $t("Discussion Comments Requests") }}</strong
                >
              </h3>
            </v-col>
            <v-col cols="12" sm="12">
              <table class="table table-hover">
                <tr>
                  <th style="text-align: center">{{ $t("Comment") }}</th>
                  <th style="text-align: center">{{ $t("By") }}</th>
                  <th style="text-align: center">{{ $t("Actions") }}</th>
                </tr>
                <tr
                  v-for="(comment, index) in commentsNeedApprove"
                  :key="index"
                >
                  <td class="main-color" style="text-align: center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <p dark v-on="on" v-if="comment.body">
                          {{ comment.body.slice(0, 10) }}
                        </p>
                      </template>
                      <span>{{ comment.body }}</span>
                    </v-tooltip>
                  </td>
                  <td class="main-color" style="text-align: center">
                    {{ comment.user }}
                  </td>
                  <td class="main-color" style="text-align: center">
                    <v-btn
                      icon
                      :disabled="actionLoading"
                      :loading="actionLoading"
                      @click="approveComment(comment.id)"
                    >
                      <v-icon color="#5fb870">check</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="editCommentDialog"
      persistent
      max-width="500px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Edit Comment") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="editedComment.body"
                    rows="3"
                    solo
                  ></v-textarea>
                  <div
                    class="attachments"
                    v-if="editedComment.myAttachments.constructor === Object"
                  >
                    <v-row
                      class="images"
                      v-if="
                        editedComment.myAttachments.image &&
                        editedComment.myAttachments.image.length > 0
                      "
                    >
                      <v-col
                        cols="6"
                        sm="3"
                        v-for="(image, index) in editedComment.myAttachments
                          .image"
                        :key="index"
                      >
                        <div class="delete-attatch">
                          <v-icon
                            class="delete-attatch-icon"
                            @click.prevent="
                              openConfirmDialog(index, image.id, 'image')
                            "
                            color="red"
                            >close</v-icon
                          >
                        </div>
                        <a
                          :href="image.url"
                          target="_blank"
                          class="imgContainer"
                        >
                          <img :src="image.url" class="postImg" />
                        </a>
                      </v-col>
                    </v-row>
                    <v-row class="other">
                      <p style="width: 100%"></p>
                      <div
                        class="video"
                        v-if="
                          editedComment.myAttachments.video &&
                          editedComment.myAttachments.video.length > 0
                        "
                      >
                        <v-col
                          cols="6"
                          v-for="(file, index) in editedComment.myAttachments
                            .video"
                          :key="index"
                        >
                          <div class="delete-attatch">
                            <v-icon
                              class="delete-attatch-icon"
                              @click.prevent="
                                openConfirmDialog(index, file.id, 'video')
                              "
                              color="red"
                              >close</v-icon
                            >
                          </div>
                          <v-icon class="main-color">movie</v-icon>
                          <a :href="file.url" target="_blank">{{
                            file.name
                          }}</a>
                        </v-col>
                      </div>
                      <div
                        class="document"
                        v-if="
                          editedComment.myAttachments.document &&
                          editedComment.myAttachments.document.length > 0
                        "
                      >
                        <v-col
                          cols="6"
                          v-for="(file, index) in editedComment.myAttachments
                            .document"
                          :key="index"
                        >
                          <div class="delete-attatch">
                            <v-icon
                              class="delete-attatch-icon"
                              @click.prevent="
                                openConfirmDialog(index, file.id, 'document')
                              "
                              color="red"
                              >close</v-icon
                            >
                          </div>
                          <v-icon class="main-color">description</v-icon>
                          <a :href="file.url" target="_blank">{{
                            file.name
                          }}</a>
                        </v-col>
                      </div>
                      <div
                        class="audio"
                        v-if="
                          editedComment.myAttachments.audio &&
                          editedComment.myAttachments.audio.length > 0
                        "
                      >
                        <v-col
                          cols="6"
                          v-for="(file, index) in editedComment.myAttachments
                            .audio"
                          :key="index"
                        >
                          <div class="delete-attatch">
                            <v-icon
                              class="delete-attatch-icon"
                              @click.prevent="
                                openConfirmDialog(index, file.id, 'audio')
                              "
                              color="red"
                              >close</v-icon
                            >
                          </div>
                          <v-icon class="main-color">audiotrack</v-icon>
                          <a :href="file.url" target="_blank">{{
                            file.name
                          }}</a>
                        </v-col>
                      </div>
                    </v-row>
                  </div>

                  <div v-if="validation_errors.about">
                    <p
                      class="red--text"
                      v-for="(error, index) in validation_errors.about"
                      :key="index"
                    >
                      {{ error }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <p v-if="commentFileNames != ''">{{ commentFileNames }}</p>
          <!-- class="myFileBrowser" -->
          <input
            type="file"
            class="myFileBrowser"
            multiple
            ref="commentattachedited"
            @change="readFilesCommentEdited"
          />
          <v-icon class="main-color" style="cursor: pointer; margin-left: 20px"
            >attach_file</v-icon
          >
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel cancelBtn"
            @click="editCommentDialog = false"
            >{{ $t("Close") }}</v-btn
          >
          <v-btn
            class="modal-btn-save saveBtn"
            @click="updateComment()"
            :loading="loadingNewPost"
            :disabled="loadingNewPost"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteCommentDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to permanently remove this comment?")
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="closeCommentDialog"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteComment"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>
          {{ $t("Are you sure you want to permanently remove this") }}
          {{ type }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn2"
            @click="confirmDialog = false"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            @click="deleteItem(deletedItem, indexItem, type)"
            class="modal-btn-save yesBtn2"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deletePostDialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to permanently remove this post?")
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="deletePostDialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deletePost"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <p style="text-align: center" v-if="posts.length == 0">
      {{ $t("No posts available") }}
    </p>

    <snackComponent
      :snackbar="snackbar"
      :snackColor="snackColor"
      :snackMsg="snackMsg"
      @closeSnack="closeSnack"
    ></snackComponent>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import axios from "axios";
import ACL from "../../acl";
import snackComponent from "@/components/snackComponent";

import { validationMixin } from "../../mixins/validationMixin";
export default {
  components: {
    VueTagsInput,
    snackComponent,
  },
  name: "discussionBoard",
  data() {
    return {
      snackbar: false,
      snackColor: "red",
      snackMsg: "",
      actionLoading: false,
      loading: false,
      Replyloading: false,
      scrolledToBottom: false,
      valid: false,
      tag: "",
      tags: [],
      groupData: [],
      posts: [],
      page: 1,
      length: "",
      search: "",
      post: {
        title: "",
        body: "",
        attachments: [],
        tags: [],
      },
      comment: [],
      deletedComment: {
        postIndex: "",
        commentIndex: "",
      },
      deleteCommentDialog: false,
      deletePostDialog: false,
      deletedPost: { postIndex: "" },
      postTitleError: "",
      postBodyError: "",
      validation_errors: [],
      loadingNewPost: false,
      loadingMorePosts: false,
      loadingComments: false,
      fileNames: "",
      commentFileNames: "",
      editedComment: {
        id: "",
        body: "",
        attachments: [],
        myAttachments: [],
      },
      superAdmin: ACL.Role("Super-Admin"),
      editCommentDialog: false,
      commentsNeedApprove: [],
      confirmDialog: false,
      deletedItem: "",
      indexItem: "",
      type: "",
      roomIsValid: "",
      user_access: "",
    };
  },
  mixins: [validationMixin],
  methods: {
    closeSnack() {
      this.snackbar = false;
    },
    showMorePosts() {
      this.getPosts();
    },
    closeCommentDialog() {
      this.editCommentDialog = false;
      this.editedComment = {
        id: "",
        body: "",
        attachments: [],
        myAttachments: [],
      };
    },
    deleteItem(item, index, type) {
      let attatchId = item;
      let attatchType = type;
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/post/deleteAttachment/" +
            attatchId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            console.log(attatchType);
            if (attatchType == "image") {
              this.editedComment.myAttachments.image.splice(index, 1);
            }
            if (attatchType == "document") {
              this.editedComment.myAttachments.document.splice(index, 1);
            }
            if (attatchType == "video") {
              this.editedComment.myAttachments.video.splice(index, 1);
            }
            if (attatchType == "audio") {
              this.editedComment.myAttachments.audio.splice(index, 1);
            }
          }
        });

      this.confirmDialog = false;
    },
    openConfirmDialog(index, imageId, attatchType) {
      this.confirmDialog = true;
      this.deletedItem = imageId;
      this.indexItem = index;
      this.type = attatchType;
    },
    showComment(postIndex) {
      this.posts[postIndex]["show_comments"] =
        !this.posts[postIndex]["show_comments"];
    },
    scroll() {
      window.onscroll = () => {
        console.log(this.$refs.discussionBoard.offsetHeight);
        console.log(
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) + window.innerHeight
        );
        console.log(
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight ===
            this.$refs.discussionBoard.offsetHeight
        );
        // console.log(document.documentElement.offsetHeight);
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight >
          this.$refs.discussionBoard.offsetHeight;

        if (bottomOfWindow) {
          this.scrolledToBottom = true; // replace it with your code
          this.getPosts();
        } else {
          this.scrolledToBottom = false;
        }
      };
    },
    OpenDiscussionCommentsRequests() {
      this.$router.push(
        {
          path:
            "/discussioncommentrequests/" +
            this.$router.currentRoute.params.roomId,
        },
        () => {}
      );
    },
    approveComment(commentId) {
      this.actionLoading = true;
      axios
        .post(
          this.getApiUrl + "/discussionboard/comments/approve/" + commentId,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.actionLoading = false;
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            location.reload();
            this.getGroupData();
            this.getPosts();
          }
        });
    },
    getGroupData() {
      axios
        .get(this.getApiUrl + "/discussionboard/show/" + this.roomId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((res) => {
          this.groupData = res.data.data;
          this.roomIsValid = this.groupData.valid_unitll;
          this.user_access = this.groupData.user_access;
          // if (res.data.data.valid_unitll == true) {
          //   console.log("room valid? " + this.roomIsValid);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCommentsNeedApprove() {
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/commentsNeedApprove/" +
            this.roomId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          this.commentsNeedApprove = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPosts(page = null) {
      var searchInput = "";
      // console.log(this.search);
      this.loadingMorePosts = true;
      let getPost = true;
      if (page != null && this.search == "") {
        this.page = page;
      } else {
        if (this.search != "") {
          this.page = 1;
          this.post = {
            title: "",
            body: "",
            attachments: [],
            tags: [],
          };
          searchInput = "&search=" + this.search;
        } else {
          if (this.page == this.length) {
            getPost = false;
          } else {
            if (this.page < this.length) {
              this.page = this.page + 1;
            } else {
              this.page = 1;
            }
          }
        }
      }
      if (getPost) {
        setTimeout(() => {
          axios
            .get(
              this.getApiUrl +
                "/discussionboard/posts?page=" +
                this.page +
                "&discussion_room_id=" +
                this.roomId +
                searchInput,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((res) => {
              this.loadingMorePosts = false;
              if (this.search != "") {
                this.posts = res.data.data.data;
              } else {
                this.posts.push(...res.data.data.data);
              }
              if (this.page == 1) {
                this.posts = res.data.data.data;
              }

              this.page = res.data.data.current_page;
              this.length = res.data.data.last_page;

              this.posts.forEach(() => {
                this.comment.push({
                  body: "",
                  post_id: "",
                  attachments: [],
                });
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }, 500);
      }
    },
    readFiles() {
      var _this = this;
      console.log(this.$refs.attachment);
      this.$refs.attachment.files.forEach((file) => {
        if (_this.fileNames.length == 0) {
          _this.fileNames = file.name;
        } else {
          _this.fileNames = _this.fileNames + ", " + file.name;
        }
        _this.post.attachments.push(file);
      });
    },
    readFilesComment(postIndex) {
      var _this = this;

      this.$refs.commentattach[0].files.forEach((file) => {
        if (_this.commentFileNames.length == 0) {
          _this.commentFileNames = file.name;
        } else {
          _this.commentFileNames = _this.commentFileNames + ", " + file.name;
        }
        _this.comment[postIndex].attachments.push(file);
      });
    },
    readFilesCommentEdited() {
      var _this = this;
      this.$refs.commentattachedited.files.forEach((file) => {
        if (_this.commentFileNames.length == 0) {
          _this.commentFileNames = file.name;
        } else {
          _this.commentFileNames = _this.commentFileNames + ", " + file.name;
        }
        // _this.comment.attachments.push(file);
        console.log(file);
        _this.editedComment.attachments.push(file);
      });
      // console.log(_this.editedComment.attachments);
    },
    savePost() {
      if (!this.valid) {
        this.validate();
      } else {
        this.loadingNewPost = true;
        // if (this.post.title != "" && this.post.body != "") {
        // save
        const formData = new FormData();
        if (this.post.attachments.length > 0) {
          this.post.attachments.forEach((file) => {
            formData.append("file[]", file);
          });
        }
        if (this.post.id && this.post.id != "") {
          formData.append("id", this.post.id);
        }
        formData.append("title", this.post.title);
        formData.append("body", this.post.body);
        formData.append("discussion_room_id", this.roomId);
        if (this.post.tags.length > 0 && this.post.tags != "") {
          this.post.tags.forEach((tag) => {
            formData.append("tags[]", tag.text);
          });
        }
        this.loading = true;
        axios
          .post(this.getApiUrl + "/discussionboard/posts/store", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.loading = false;

            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              this.loadingNewPost = false;
              this.fileNames = "";
              this.resetPostFields();
              this.getPosts(1);
              this.$refs.form.resetValidation();
            }
          });
        // } else {
        //   if (this.post.title == "") {
        //     this.postTitleError = "Post must have a topic!";
        //   }
        //   if (this.post.body == "") {
        //     this.postBodyError = "Post must have details!";
        //   }
        // }
      }
    },
    saveComment(post_id, postIndex, comment) {
      if (comment) {
        const formData = new FormData();
        if (this.comment[postIndex].attachments.length > 0) {
          this.comment[postIndex].attachments.forEach((file) => {
            formData.append("file[]", file);
          });
        }
        formData.append("body", this.comment[postIndex].body);
        formData.append("post_id", post_id);
        this.Replyloading = true;
        axios
          .post(this.getApiUrl + "/discussionboard/comments/store", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.Replyloading = false;
            this.comment[postIndex].body = "";
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              this.resetCommentFields();
              this.commentFileNames = "";
              this.getPosts(1);
              this.comment[postIndex].attachments = [];
            }
          });
      } else {
        this.snackbar = true;
        this.snackMsg = this.$i18n.t("Reply field is required");
        setTimeout(() => {
          this.snackbar = false;
        }, 2000);
      }
    },
    updateComment() {
      this.loadingComments = true;
      const formData = new FormData();
      if (this.editedComment.attachments.length > 0) {
        this.editedComment.attachments.forEach((file) => {
          formData.append("file[]", file);
        });
      }
      formData.append("body", this.editedComment.body);
      // console.log(this.editedComment);
      axios
        .post(
          this.getApiUrl +
            "/discussionboard/comments/update/" +
            this.editedComment.id,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            this.loadingComments = false;
            this.editCommentDialog = false;
            this.editedComment = {
              id: "",
              body: "",
              attachments: [],
              myAttachments: [],
            };
            this.commentFileNames = "";
            this.getPosts(1);
          }
        });
    },
    editComment(postIndex, commentIndex) {
      this.editedComment.id = this.posts[postIndex].comments[commentIndex].id;
      this.editedComment.body =
        this.posts[postIndex].comments[commentIndex].body;
      this.editedComment.myAttachments =
        this.posts[postIndex].comments[commentIndex].attachments;
      // console.log(this.editedComment);
      this.editedComment.attachments = [];
      this.editCommentDialog = true;
    },
    // editPost(postIndex) {
    //   this.post.id = this.posts[postIndex].id;
    //   this.post.title = this.posts[postIndex].title;
    //   this.post.body = this.posts[postIndex].body;
    //   this.post.tags = this.posts[postIndex].tags;
    //   // this.post.attachments = this.posts[postIndex].attachments;
    //   window.scrollTo(0, 0);
    // },
    editPost(postIndex) {
      this.$router.push(
        {
          path:
            "/discussionrequests/editPost/" +
            this.$router.currentRoute.params.roomId +
            "/" +
            this.posts[postIndex].id,
        },
        () => {}
      );
      // this.post.id = this.posts[postIndex].id;
      // this.post.title = this.posts[postIndex].title;
      // this.post.body = this.posts[postIndex].body;
      // this.post.tags = this.posts[postIndex].tags;
      // this.post.attachments = this.posts[postIndex].attachments;
      // window.scrollTo(0, 0);
    },
    deleteCommentDialogOpen(postIndex, commentIndex) {
      this.deletedComment.postIndex = postIndex;
      this.deletedComment.commentIndex = commentIndex;
      this.deleteCommentDialog = true;
    },
    deleteComment() {
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/comments/destroy/" +
            this.posts[this.deletedComment.postIndex].comments[
              this.deletedComment.commentIndex
            ].id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.posts[this.deletedComment.postIndex].comments.splice(
              this.deletedComment.commentIndex,
              1
            );
            this.deleteCommentDialog = false;
          }
        });
    },
    deletePostDialogOpen(postIndex) {
      this.deletedPost.postIndex = postIndex;
      this.deletePostDialog = true;
    },
    deletePost() {
      axios
        .get(
          this.getApiUrl +
            "/discussionboard/posts/destroy/" +
            this.posts[this.deletedPost.postIndex].id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.posts.splice(this.deletedPost.postIndex, 1);
            this.deletePostDialog = false;
          }
        });
    },
    approve(post_id) {
      this.actionLoading = true;
      axios
        .post(
          this.getApiUrl + "/discussionboard/posts/approve/" + post_id,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.actionLoading = false;
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            // location.reload();
            this.getGroupData();
            this.getPosts();
          }
        });
    },
    filterByTag(tagName) {
      this.search = tagName;
      this.getPosts(1);
    },
    showReplyArea(index) {
      this.posts[index].show_reply_area = !this.posts[index].show_reply_area;
    },
    resetPostFields() {
      this.post.title = "";
      this.post.body = "";
      this.post.attachments = [];
      this.post.tags = "";
      this.tags = [];
      this.tag = "";
    },
    resetCommentFields() {
      this.comment.body = "";
      this.comment.post_id = "";
      this.comment.attachments = [];
    },
    focus() {
      this.postTitleError = "";
      this.postBodyError = "";
    },
    OpenDiscussionRequests() {
      this.$router.push(
        {
          path:
            "/discussionrequests/" + this.$router.currentRoute.params.roomId,
        },
        () => {}
      );
    },
  },
  computed: {
    filteredItems() {
      return this.groupData.tagsAutoComplete.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  mounted() {
    this.roomId = this.$router.currentRoute.params.roomId;
    this.getPosts();
    this.getGroupData();
    this.getCommentsNeedApprove();
    // this.scroll();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
.addTopic {
  padding: 20px 20px;
  background-color: #f5f6f7;
}
.icon {
  margin-left: 3rem;
}
.comment-item {
  background-color: #eef3ff;
  margin-top: 10px;
}

.discussion {
  .title {
    color: #7297ff;
    font-size: 15px !important;
    font-weight: normal !important;
  }
  .item {
    box-shadow: $box-shadow;
    padding: 10px 20px;
    margin-bottom: 1rem;
    .person-info {
      display: inline-block;
      margin-left: 1rem;
      .name {
        font-size: 16px;
        margin-bottom: 0 !important;
      }
      .time {
        margin-bottom: 0 !important;
        font-size: 12px;
      }
    }
    .topicContent {
      margin-top: 1rem;
      padding-left: 10px;
    }
    .topicInfo {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
  }
}
.tags {
  padding: 10px;
  margin-bottom: 1rem;
  // box-shadow: $box-shadow;
  .cat-num {
    font-size: 12px;
  }
}
.about-group {
  margin-bottom: 1rem;
  padding: 10px;
  background-color: #f5f6f7;
}

.members {
  padding: 10px;
  background-color: #eef3ff;
}

.myFileBrowser {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 999;
}

.post-error {
  font-size: 14px;
  color: red;
}
.reaction {
  cursor: pointer;
}
.attachments .imgContainer {
  display: block;
  height: 100%;
  position: relative;
}
.attachments .postImg {
  border: 2px solid transparent;
  // border-radius: 50px;
  width: 100%;
}
.video,
.document,
.audio {
  width: 100%;
  margin-bottom: 10px;
}

.deleteAction {
  margin: 0 10px;
}

.chat-line {
  /* Support enters in our demo, propably not needed in production */
  white-space: pre;
}
.square {
  border: 1px solid #ddd;
  padding: 1px;
  margin-right: 5px;
  border-radius: 3px;
}

.text-left {
  text-align: left !important;
}
</style>
